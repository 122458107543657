import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';

import Layout from '../components/shared/layout';
import SEO from '../components/shared/seo';

import ToursSection from '../components/tours/tours-section';

export const query = graphql`
	query {
		prismicToursPage {
			prismicId
			data {
				blackboard_heading {
					text
				}
				blackboard_details {
					html
				}
				additional_details {
					html
				}
				book_tour_button_is_visible
				video_link {
					url
				}
			}
		}
	}
`;

const PageTemplate = ({ data }) => (
	<Layout>
		<SEO title="Tours" />
		<ToursSection
			bb_head={data.prismicToursPage.data.blackboard_heading.text}
			bb_content={data.prismicToursPage.data.blackboard_details.html}
			details={data.prismicToursPage.data.additional_details.html}
			tourButtonIsVisible={
				data.prismicToursPage.data.book_tour_button_is_visible
			}
			videoLink={data.prismicToursPage.data.video_link.url}
		/>
	</Layout>
);

export default withPreview(PageTemplate);
