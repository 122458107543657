import React from 'react';
import styled from 'styled-components';

import { ButtonOne } from '../../../styles/buttons';

//import toursBg from './images/tours-bg.jpg';
import toursBg from './images/FlyingMonkeys_Tours_BG_Desktop2.jpg';

const StyledSection = styled.section`
	background-image: url(${toursBg});
	background-size: 100%;
	/*min-height: 2114px;*/
	min-height: 165vw;

	.vid-link {
		display: inline-block;
		position: absolute;
		top: 28vw;
		left: 56vw;
		width: 6vw;
		height: 6vw;
	}
	.panel {
		h2 {
			font-size: 2rem;
			margin-bottom: 2rem;
		}
		p {
			font-size: 18px;
			margin-bottom: 1rem;
		}
		button {
			transform: translateX(-35px);
		}
		background-color: rgba(0, 0, 0, 0.9);
		max-width: 800px;
		position: absolute;
		padding: 2rem;
		top: 580px;
		left: 100px;
		color: white;
	}
	.col {
		width: 46vw;
		position: absolute;
		right: 4vw;
		top: 50vw;
		.board {
			transform: rotate(2deg);
			text-align: center;
			color: white;
			padding: 5vw 0;
			height: 31vw;
			h2 {
				font-size: 3vw;
				margin-bottom: 0.5em;
			}
			h3 {
				font-size: 1.5vw;
			}
			p {
				font-size: 1.4vw;
			}
			@media (max-width: 999px) {
				height: 55vw;
			}
		}
		h2 {
			font-size: 2.5rem;
			margin-bottom: 1rem;
		}
		h3 {
			font-size: 18px;
			font-family: ${(p) => p.theme.font.body};
			font-weight: 700;
		}
		.split {
			display: flex;
			div {
				flex: 1;
				padding: 3vw;
			}
		}
		.is-centered {
			text-align: center;
			padding-top: 2rem;
		}
		.below {
			padding: 0 2vw;
			text-align: left;
			color: black;
			margin-top: 5vw;
			p {
				font-size: 18px;
				margin-bottom: 1rem;
				margin: 0;
				margin-bottom: 1rem;
			}
		}
	}
	@media (max-width: 999px) {
		background-size: 198%;
		background-position: 95% 0;
		min-height: 320vw;
		.vid-link {
			top: 50vw;
			left: 22vw;
			width: 10vw;
			height: 10vw;
		}
		.col {
			width: 100vw;
			top: 105vw;
			left: 0;
			h2 {
				font-size: 1.5rem;
			}
			.split {
				div {
					padding: 7vw;
				}
			}
			.board {
				margin: 0 10vw;
				h2 {
					font-size: 6vw;
					margin-bottom: 0.5em;
				}
				h3 {
					font-size: 3vw;
				}
				p {
					font-size: 2.6vw;
				}
			}
			.below {
				padding: 0 5vw;
				margin-top: 8vw;
				h2 {
					margin-bottom: 0px;
				}
				p {
					font-size: 15px;
				}
				.is-centered {
					padding-top: 0;
					button {
						font-size: 1.25rem;
					}
				}
			}
		}
	}
`;

export default ({
	bb_head,
	bb_content,
	details,
	tourButtonIsVisible,
	videoLink,
}) => (
	<StyledSection>
		<a
			href={videoLink}
			className="vid-link"
			target="_blank"
			rel="noopener noreferrer"
		></a>
		<div className="col">
			<div className="board">
				<h2>{bb_head}</h2>
				<div dangerouslySetInnerHTML={{ __html: bb_content }} />
			</div>
			<div className="below">
				<div dangerouslySetInnerHTML={{ __html: details }} />
				<div className="is-centered">
					{tourButtonIsVisible && (
						<ButtonOne>
							<a
								href="https://calendly.com/flyingmonkeys/brewery-tour?web=1&wdLOR=c0B3E3F0C-3E49-AC45-89C4-8BCCD9DD9593&month=2021-02"
								target="_blank"
								rel="noopener noreferrer"
							>
								BOOK YOUR TOUR
							</a>
						</ButtonOne>
					)}
					<ButtonOne>
						<a
							href="https://goo.gl/maps/uAyYp4ufMvryztzL7"
							target="_blank"
							rel="noopener noreferrer"
						>
							HOW TO GET HERE
						</a>
					</ButtonOne>
				</div>
			</div>
		</div>
	</StyledSection>
);
